import { createContext, useContext, useReducer } from 'react';

import orderReducer, { initialState } from '../../library/common/reducers/ordersContextReducer';

const OrderContext = createContext();

export const useOrderContext = () => useContext(OrderContext);

const OrderProvider = ({ children }) => {
    const useOrderContextState = useReducer(orderReducer, initialState);

    return (
        <OrderContext.Provider value={[...useOrderContextState]}>
            {children}
        </OrderContext.Provider>
    );
};

export default OrderProvider;