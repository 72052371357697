import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../../components/Table/Table';
import { postRequest } from '../../../../helpers/httpHelper';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { month } from '../../../../utils/common';
import { Link } from 'react-router-dom';

const MagazineTable = ({
  //   setMagazineSearchForm,
  openModal,
  refreshTable,
  setRefreshTable
  //   filterMagazine
}) => {
  // const navigate = useNavigate();
  // const url = useLocation();

  // console.log("url?.pathname => ", url?.pathname);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [debouncedGlobalFilter, setDebouncedGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  // Debounce effect for globalFilter
  useEffect(() => {
    const handler = setTimeout(() => {
      setGlobalFilter(debouncedGlobalFilter);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedGlobalFilter]);

  const fetchData = () => {
    setIsLoading(true);
    dayjs.extend(utc);

    postRequest(
      'list-magazine',
      {
        status: '',
        start: pagination.pageIndex * pagination.pageSize + 1,
        limit: pagination.pageSize,
        showAll: 1,
        ownOnly: null,
        sorting:
          sorting && sorting.length > 0
            ? sorting.map((item) => ({
                id: item.id || '',
                desc: item.desc !== undefined ? item.desc : true
              }))
            : [{ id: '', desc: true }],
        searchPrams: {
          any: globalFilter || "",
        },
      },
      (response) => {
        setIsLoading(false);
        if (!response?.data?.error && response?.status === 200) {
          setData(response?.data?.data);
          setRowCount(response?.data?.totalRecords);
        }
      }
    );

    setIsLoading(false);
  };

  useEffect(() => {
    if (refreshTable) fetchData();
    setRefreshTable(false);
  }, [refreshTable]);

  useEffect(() => {
    fetchData();
  }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

  // const monthname = (monthData) => {
  //   const monthName = month?.find((m) => m.id === monthData);

  //   return monthName?.name;
  // };

  const toolbarButtons = [
    {
      variant: 'customcreate',
      sx: {
        textTransform: 'none',
        backgroundColor: '#ff8035',
        color: 'black',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: '#e6732f'
        }
      },
      color: 'primary',
      startIcon: <ControlPointIcon />,
      //   onClick: () => url?.pathname?.split('/').pop() === 'e-magazine' && navigate('add-e-magazine'),
      onClick: () => openModal('magazine'),
      label: 'Add Magazine'
    }
    // {
    //   variant: 'contained',
    //   sx: {
    //     textTransform: 'none',
    //     backgroundColor: '#ffffff',
    //     color: 'black',
    //     borderRadius: '8px',
    //     '&:hover': {
    //       backgroundColor: '#e6732f'
    //     }
    //   },
    //   color: 'primary',
    //   startIcon: <FilterListIcon />,
    //   onClick: () => setMagazineSearchForm((prev) => !prev),
    //   label: 'Filter'
    // }
  ];

  const columns = useMemo(() => [
    {
      accessorKey: 'magazineTitle',
      header: 'Magazine Title',
      size: 100
    },
    {
      accessorKey: 'month',
      header: 'Month Name',
      size: 100
      // Cell: ({ row }) => {
      //   return <>{monthname(row?.original?.month) || ''}</>;
      // }
    },
    {
      accessorKey: `year`,
      header: 'Year',
      size: 100
    },
    {
      accessorKey: 'magazineUrl',
      header: 'Magazine URL',
      size: 100,
      Cell: ({ row }) => {
        const fileUrl = row.original.magazineUrl;
        return (
          // <Link to={fileUrl} target="_blank">
          //   {row.original.magazineUrl}
          // </Link>
          <div
            style={{
              width: 'inherit',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            <Link to={fileUrl} target="_blank">
              {row?.original?.magazineUrl || ''}
            </Link>
          </div>
        );
      }
    },
    {
      accessorKey: 'isActiveUrl',
      header: 'Status',
      size: 100,
      Cell: ({ row }) => {
        return <>{row?.original?.isActiveUrl === true ? 'Active' : 'InActive'}</>;
      }
    }
    // {
    //   accessorKey: 'cost',
    //   header: 'Status',
    //   size: 100
    //   // Cell: ({ row }) => {
    //   //     return <>{row?.original?.projectCost?.[0]?.cost || ''}</>;
    //   // }
    // },
  ]);

  const rowActionItems = [
    {
      icon: <EditIcon />,
      label: 'Edit',
      onClick: (row) => openModal('magazine', row)
      //   onClick: (row) => { {
      //       navigate(`/app/magazine/e-magazine/edit-e-magazine-details/${row?.id || row?._id?.$oid}`);
      //     }
      //   }
    }
    // {
    //   icon: <DeleteIcon />,
    //   label: 'Delete',
    //   onClick: (row) => {
    //     //vivek sir will confirm...
    //     //deleteCompany(row?.id);
    //   }
    // }
  ];
  const state = {
    enableRowActions: true,
    enableRowSelection: false,
    manualSorting: true, // true, if backend handle
    manualFiltering: true // true, if backend handle
  };

  return (
    <Table
      columns={columns}
      data={data}
      isLoading={isLoading}
      isError={isError}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      globalFilter={debouncedGlobalFilter}
      setGlobalFilter={setDebouncedGlobalFilter}
      sorting={sorting}
      setSorting={setSorting}
      pagination={pagination}
      setPagination={setPagination}
      rowCount={rowCount}
      toolbarButtons={toolbarButtons}
      rowActionItems={rowActionItems}
      state={state}
    />
  );
};

export default MagazineTable;
