import React from 'react';
import Box from '@mui/material/Box';
import { Snackbar, Alert } from '@mui/material';

const SnackbarAlert = (props) => {

  const { open, message, severity, vertical, horizontal } = props;
  return (
    <Box>
      <Snackbar
       sx={{ position: 'fixed', bottom:'30px', left: '0px', right: '0px', padding:'0' }}
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}>
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default SnackbarAlert;
