import { createContext, useContext, useReducer } from 'react';

import leadReducer, { initialState } from '../../library/common/reducers/leadContextReducer';

const LeadContext = createContext();

export const useLeadContext = () => useContext(LeadContext);

const LeadProvider = ({ children }) => {
    const useLeadContextState = useReducer(leadReducer, initialState);

    return (
        <LeadContext.Provider value={[...useLeadContextState]}>
            {children}
        </LeadContext.Provider>
    );
};

export default LeadProvider;