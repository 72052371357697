import * as actionTypes from '../constants/StoreConstants';

export const initialState = {
    basicInfo: {},
    orderDetails: {}
};



const orderReducer = (state, { type, payload }) => {
    switch (type) {
        case actionTypes?.SET_ORDERBASICINFO:
            // return { ...state, basicInfo: { ...state.basicInfo, ...payload } };
            return { ...state, basicInfo: payload && Object?.keys(payload)?.length === 0 ? {} : { ...state.basicInfo, ...payload } }
        case actionTypes?.SET_ORDERDETAILS:
            return { ...state, orderDetails: { ...state.orderDetails, ...payload } };
        default:
            return state;
    }
};

export default orderReducer;
