/**
 * Auth Reducer
 */

export const SET_AUTH = 'SET_AUTH';

//company
export const SET_COMPANYDETAILS = 'SET_COMPANYDETAILS';
export const SET_NOTESDETAILS = 'SET_NOTESDETAILS';

//project
export const SET_PROJECTDETAILS = 'SET_PROJECTDETAILS';
export const SET_PROJECTHISTORY = 'SET_PROJECTHISTORY';
export const SET_PROJECTCOST = 'SET_PROJECTCOST';
export const SET_PROJECTOFFICIAL = 'SET_PROJECTOFFICIAL';
export const SET_SECTORDETAILS = 'SET_SECTORDETAILS';
export const SET_SUBSECTORTDETAILS = 'SET_SUBSECTORTDETAILS';

//tenders
export const SET_TENDERSDETAILS = 'SET_TENDERSDETAILS';
export const SET_TENDERINFO = 'SET_TENDERINFO';

//orders
export const SET_ORDERBASICINFO = 'SET_ORDERBASICINFO';
export const SET_ORDERDETAILS = 'SET_ORDERDETAILS';

//news
export const SET_NEWS = 'SET_NEWS';
export const SET_NEWSMULTIPLE = 'SET_NEWSMULTIPLE';
export const SET_NEWSCONTENT = 'SET_NEWSCONTENT';

// sales-CRM  > lead
export const SET_LEADINFO = 'SET_LEADINFO';
export const SET_LEADINVOICE = 'SET_LEADINVOICE'

export const UPDATE_SUBMENU_COUNT = 'UPDATE_SUBMENU_COUNT';
