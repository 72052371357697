import React, { lazy } from 'react';
import CompanyProvider from '../contexts/companyContext';
import { inboxRoutes } from '../../library/common/constants/navConstants';
import ProjectProvider from '../contexts/projectContext';
import {
  projectConfigurationRoutes,
  companyConfigurationRoutes
} from '../../library/common/constants/navConstants';
import AddRoleAccess from '../../screens/Main/UserManagement/Role/AddRoleAccess';
import TendersProvider from '../contexts/tendersContext';
import { masterConfigModules } from '../../library/common/constants/mastersConfigConstants';
import OrderProvider from '../contexts/orderContext'
import NewsProvider from '../contexts/newsContext'
import Magazine from '../../screens/Main/Magazine/Magazine';
import LeadProvider from '../contexts/leadContext';
// import AddMagazine from '../../screens/Main/Magazine/AddMagazine/AddMagazine';

//company
const MyCompanies = lazy(() => import('../../screens/Main/Company/MyCompanies/MyCompanies'));
const Companies = lazy(() => import('../../screens/Main/Company/Companies/Companies'));
const InboxTable = lazy(() => import('../../screens/Main/Company/Inbox/InboxTable/InboxTable'));
const AddCompany = lazy(() => import('../../screens/Main/Company/Companies/AddCompany/AddCompany'));

//project
const AddProject = lazy(() => import('../../screens/Main/Project/Projects/AddProject/AddProject'));
const MyProject = lazy(() => import('../../screens/Main/Project/MyProject/MyProject'));
const Project = lazy(() => import('../../screens/Main/Project/Projects/Projects'));
const InboxTableProject = lazy(
  () => import('../../screens/Main/Project/Inbox/InboxTable/InboxTableProject')
);

//users
const Role = lazy(() => import('../../screens/Main/UserManagement/Role/RoleManagement'));
const User = lazy(() => import('../../screens/Main/UserManagement/User/UserManagement'));

//tenders
const MyTender = lazy(() => import('../../screens/Main/Tender/MyTenders/MyTenders'));
const Tenders = lazy(() => import('../../screens/Main/Tender/Tenders/Tenders'));
const SecondarySubmenuTenders = lazy(
  () => import('../../screens/Main/Tender/SecondarySubmenuTenders/SecondarySubmenuTenders')
);
const AddTender = lazy(() => import('../../screens/Main/Tender/Tenders/AddTender/AddTender'));

//order
const AddOrder = lazy(() => import('../../screens/Main/Order/Orders/AddOrder/AddOrder'));
const MyOrder = lazy(() => import('../../screens/Main/Order/MyOrders/MyOrders'));
const Order = lazy(() => import('../../screens/Main/Order/Orders/Orders'));
const SecondarySubmenuOrders = lazy(() => import('../../screens/Main/Order/SecondarySubmenuOrders/SecondarySubmenuOrders'));

//news
const AddNews = lazy(() => import('../../screens/Main/News/News/AddNews/AddNews'));
const MyNews = lazy(() => import('../../screens/Main/News/MyNews/MyNews'));
const News = lazy(() => import('../../screens/Main/News/News/News'));
const SecondarySubmenuNews = lazy(() => import('../../screens/Main/News/SecondarySubmenuNews/SecondarySubmenuNews'));

//master config
const MasterConfig = lazy(
  () => import('../../screens/Main/MasterConfiguration/MasterConfiguration')
);

//sales crm - lead
const AddLeads = lazy(() => import('../../screens/Main/SalesCRM/Leads/AddLeads/AddLeads'))
const Leads = lazy(() => import('../../screens/Main/SalesCRM/Leads/Leads'))

const SubNavMenuCompany = [
  {
    path: 'mycompanies',
    element: (
      <>
        <MyCompanies />
      </>
    )
  },
  {
    path: 'companies',
    element: (
      <>
        <Companies />
      </>
    )
  },
  {
    path: 'companies/add-company',
    element: (
      <CompanyProvider>
        <AddCompany />
      </CompanyProvider>
    )
  },
  {
    path: 'companies/edit-company-details/:companyID',
    element: (
      <CompanyProvider>
        <AddCompany />
      </CompanyProvider>
    )
  },
  {
    path: 'companies/view-company-details/:companyID',
    element: (
      <CompanyProvider>
        <AddCompany viewMode={true} />
      </CompanyProvider>
    )
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <InboxTable />
  }))
];

const SubNavMenuProjects = [
  {
    path: 'myprojects',
    element: <MyProject />
  },
  {
    path: 'projects',
    element: <Project />
  },
  {
    path: 'projects/add-project',
    element: (
      <ProjectProvider>
        <AddProject />
      </ProjectProvider>
    )
  },
  {
    path: 'projects/edit-project-details/:projectID',
    element: (
      <ProjectProvider>
        <AddProject />
      </ProjectProvider>
    )
  },
  {
    path: 'projects/view-project-details/:projectID',
    element: (
      <ProjectProvider>
        <AddProject viewMode={true} />
      </ProjectProvider>
    )
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <InboxTableProject />
  }))
];

const SubNavMenuTenders = [
  {
    path: 'mytender',
    element: <MyTender />
  },
  {
    path: 'tender',
    element: <Tenders />
  },
  {
    path: 'tender/add-tender',
    element: (
      <TendersProvider>
        <AddTender />
      </TendersProvider>
    )
  },
  {
    path: 'tender/edit-tender-details/:tenderID',
    element: (
      <TendersProvider>
        <AddTender />
      </TendersProvider>
    )
  },
  {
    path: 'tender/view-tender-details/:tenderID',
    element: (
      <TendersProvider>
        <AddTender viewMode={true} />
      </TendersProvider>
    )
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <SecondarySubmenuTenders />
  }))

  // Add more tenders-related routes as needed
];

// const SubNavMenuContracts = [
//   {
//     path: 'myproject',
//     element: <MyProject />
//   },
//   {
//     path: 'projects',
//     element: (
//       <>
//         <Project />
//       </>
//     )
//   }
//   // Add more contract-related routes as needed
// ];

const SubNavMenuOrder = [
  {
    path: 'myorder',
    element: (
      <>
        <MyOrder />
      </>
    )
  },
  {
    path: 'order',
    element: (
      <>
        <Order />
      </>
    )
  },
  {
    path: 'order/add-order',
    element: (
      <OrderProvider>
        <AddOrder />
      </OrderProvider>
    )
  },
  {
    path: 'order/edit-order-details/:orderID',
    element: (
      <OrderProvider>
        <AddOrder />
      </OrderProvider>
    )
  },
  {
    path: 'order/view-order-details/:orderID',
    element: (
      <OrderProvider>
        <AddOrder viewMode={true} />
      </OrderProvider>
    )
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <SecondarySubmenuOrders />
  }))
];

const SubNavMenuNews = [
  {
    path: 'mynews',
    element: (
      <>
        <MyNews />
      </>
    )
  },
  {
    path: 'news',
    element: (
      <>
        <News />
      </>
    )
  },
  {
    path: 'news/add-news',
    element: (
      <NewsProvider>
        <AddNews />
      </NewsProvider>
    )
  },
  {
    path: 'news/edit-news-details/:newsID',
    element: (
      <NewsProvider>
        <AddNews />
      </NewsProvider>
    )
  },
  {
    path: 'news/view-news-details/:newsID',
    element: (
      <NewsProvider>
        <AddNews viewMode={true} />
      </NewsProvider>
    )
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <SecondarySubmenuNews />
  }))
];

const SubNavMenuMagazine = [
  {
    path: 'e-magazine',
    element: < Magazine />
  },
  {
    path: 'survey',
    element: (
      <>
        <>UI Not Confirmed</>
      </>
    )
  },
  // {
  //   path: 'e-magazine/add-e-magazine',
  //   element: (
  //       <AddMagazine />
  //   )
  // },
  // {
  //   path: 'e-magazine/add-e-magazine/:magazineID',
  //   element: (
  //       <AddMagazine />
  //   )
  // },
  // Add more contract-related routes as needed
];

const SubNavMenuUser = [
  {
    path: 'user',
    element: <User />
  },
  {
    path: 'role',
    element: <Role />
  },
  {
    path: 'role/edit-role-details/:roleID',
    element: <AddRoleAccess />
  }
];

const SubNavMenuProjectConfig = [
  ...(projectConfigurationRoutes?.map((route, index) => ({
    path: route.to,
    element: <MasterConfig key={index} label={masterConfigModules?.project} route={route} />
  })) || [])
];

const SubNavMenuCompanyConfig = [
  ...(companyConfigurationRoutes?.map((route, index) => ({
    path: route.to,
    element: <MasterConfig key={index} label={masterConfigModules?.company} route={route} />
  })) || [])
];

const SubNavMenuSalesCRM = [
  {
    path: 'dashboard',
    element: <>Dashboard</>
  },
  {
    path: 'lead',
    element: <Leads />
  },
  {
    path: 'lead/add-lead',
    element: (
      <LeadProvider>
        <AddLeads />
      </LeadProvider>
    )
  },
  {
    path: 'client-proposal',
    element: <>Client Proposal</>
  },

];

export {
  SubNavMenuCompany,
  SubNavMenuProjects,
  SubNavMenuTenders,
  // SubNavMenuContracts,
  SubNavMenuUser,
  SubNavMenuProjectConfig,
  SubNavMenuCompanyConfig,
  SubNavMenuOrder,
  SubNavMenuNews,
  SubNavMenuMagazine,
  SubNavMenuSalesCRM
};
