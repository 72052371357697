import React, { useState } from 'react';
import { FastField, Form, Formik } from 'formik';
import {
  Autocomplete,
  Grid2,
  Box,
  TextField,
  Button,
  Divider,
  Typography,
  FormHelperText,
  styled,
  IconButton,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material';
import { mixed, object, string } from 'yup';
import { postDocumentRequest, postRequest } from '../../../../helpers/httpHelper';
import { CloudUpload as CloudUploadIcon, Close as CloseIcon } from '@mui/icons-material';
import { month, validationRegex } from '../../../../utils/common';

const AddMagazine = ({ setRefreshTable, rowData, showSnackBar, closeModal }) => {
  // const [snackbarState, setSnackbarState] = useState(defaultSnackBarState);

  // const fileInputRef = React.useRef();

  // Create separate refs for each file input
  const magazineFileRef = React.useRef();
  const documentFileRef = React.useRef();

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  });

  const initialValues =
    rowData === null
      ? // Add
        {
          magazineTitle: '',
          month: '',
          year: '',
          magazineUrl: '',
          magazineAttachment: null,
          magazineFirstPageImage: null,
          isActiveUrl: false,
          magazineDescription: ''
        }
      : // Edit
        {
          ...rowData,
          magazineTitle: rowData?.magazineTitle || '',
          month: rowData?.month || '',
          year: rowData?.year || '',
          magazineUrl: rowData?.magazineUrl || '',
          magazineAttachment: rowData?.magazineAttachment || null,
          magazineFirstPageImage: rowData?.magazineFirstPageImageName || null,
          isActiveUrl: rowData?.isActiveUrl === true ? true : false || false,
          magazineDescription: rowData?.magazineDescription || ''
        };

  const validationSchema = object().shape(
    {
      magazineAttachment: mixed()
        .test(
          'fileOrUrlRequired',
          'Either upload a magazine URL attachment or provide a Magazine URL.',
          function (value) {
            const { magazineUrl } = this.parent; // Access sibling field
            return (
              (!!value && (typeof value === 'string' || value instanceof File)) || !!magazineUrl
            );
          }
        )
        .test('fileSize', 'File size must be less than 2 MB', function (value) {
          if (value && value instanceof File) {
            return value.size <= 2097152;
          }
          return true;
        })
        .nullable(),

      magazineUrl: string()
        .matches(validationRegex.url, 'Invalid URL format')
        .test(
          'urlOrFileRequired',
          'Either upload a magazine URL attachment or provide a Magazine URL.',
          function (value) {
            const { magazineAttachment } = this.parent; // Access sibling field
            return !!value || !!magazineAttachment;
          }
        )
        .nullable(),

      magazineFirstPageImage: mixed()
        .required('Only image files (JPG, PNG) are required.')
        .test('fileType', 'Only image files (JPG, PNG) are allowed.', function (value) {
          if (value && value instanceof File) {
            const validTypes = ['image/jpeg', 'image/png'];
            return validTypes.includes(value.type);
          }
          return true;
        })
        .test('fileSize', 'File size must be less than 2 MB', function (value) {
          if (value && value instanceof File) {
            return value.size <= 2097152;
          }
          return true;
        }),

      magazineTitle: string()
        .min(2, 'Must be 2 characters or more')
        .max(200, 'Must be 200 characters or less')
        .matches(validationRegex.blankSpace, validationRegex.blankSpacesMessage)
        .required('Magazine Title is required'),

      month: string().required('Select Month is required'),
      year: string().required('Year is required'),
      isActiveUrl: string().notRequired(),
      magazineDescription: string()
        .min(2, 'Must be 2 characters or more')
        .max(200, 'Must be 200 characters or less')
        .matches(validationRegex.blankSpace, validationRegex.blankSpacesMessage)
        .notRequired()
    },
    [
      //Yup dependencies to avoid Cyclic dependency For Contact List
      ['magazineUrl', 'magazineAttachment']
    ]
  );

  const handleMagazine = (payload, resetForm) => {
    // console.log('payload => ', payload);
    // Create a copy of the payload to avoid mutating the original
    const sanitizedPayload = { ...payload };

    // Remove magazineFirstPageImageName and magazineAttachmentName if they exist
    delete sanitizedPayload.magazineFirstPageImageName;
    delete sanitizedPayload.magazineAttachmentName;

    if (rowData && typeof sanitizedPayload.magazineAttachment === 'string') {
      // console.log(
      //   'typeof payload.magazineAttachment => ',
      //   typeof sanitizedPayload.magazineAttachment
      // );
      delete sanitizedPayload.magazineAttachment;
    }
    if (rowData && typeof sanitizedPayload.magazineFirstPageImage === 'string') {
      delete sanitizedPayload.magazineFirstPageImage;
    }

    // If editing (rowData exists), add the currentMagazine_ID
    const editedPayload = {
      ...sanitizedPayload,
      isActiveUrl: sanitizedPayload?.isActiveUrl === true ? 1 : 0,
      ...(rowData && {
        currentMagazine_ID: rowData?.id
      })
    };

    postDocumentRequest('create-update-magazine', editedPayload, (response) => {
      if (!response?.data?.error && response?.status === 200) {
        setRefreshTable(true);
        showSnackBar('success', response.data.message);
        resetForm();
        closeModal();
      } else {
        // Handling error messages
        const errors = response?.data?.message || {};
        const mappedErrors = Object.entries(errors).map(([field, errorMessages]) => {
          return {
            field,
            message: errorMessages.join(', ')
          };
        });

        // Optionally display errors in a snack bar or UI
        mappedErrors.forEach(({ field, message }) => {
          showSnackBar('error', `${field}: ${message}`);
        });
      }
    });
  };

  const scheme = []; // remove this

  const currentYear = new Date().getFullYear(); // e.g., 2025
  const years = Array.from({ length: 26 }, (_, index) => currentYear + index);

  // Now, years = [2025, 2026, 2027, ..., 2050]

  return (
    <Box px={2}>
      <Typography variant="h5">{rowData ? 'Edit Magazine' : 'Add Magazine'}</Typography>
      <Divider sx={{ marginBlockStart: '1rem', marginBlockEnd: '1rem' }} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleMagazine(values, resetForm);
          // resetForm();
        }}
        enableReinitialize>
        {({
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          values,
          dirty,
          isValid,
          handleBlur,
          setFieldError
        }) => {
          // console.log('values => ', values);
          // console.log('errors => ', errors);
          // console.log('touched => ', touched);
          return (
            <>
              <Form id="AddMagazineID" onSubmit={handleSubmit}>
                <Grid2
                  container
                  rowSpacing={2}
                  cellSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid2 size={{ xs: 12, sm: 8, md: 4 }}>
                    <FastField
                      as={TextField}
                      name="magazineTitle"
                      id="magazineTitle"
                      label="Magazine Title*"
                      fullWidth
                      size="small"
                      error={errors?.magazineTitle && touched?.magazineTitle}
                    />
                    {errors?.magazineTitle && touched?.magazineTitle && (
                      <FormHelperText error>{errors?.magazineTitle}</FormHelperText>
                    )}
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 8, md: 4 }}>
                    <Autocomplete
                      id="month"
                      name="month"
                      options={month}
                      getOptionLabel={(option) => option?.name || ''}
                      fullWidth
                      size="small"
                      value={month.find((m) => m.id === values?.month) || null}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(e, value) => {
                        setFieldValue('month', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Month*"
                          onBlur={handleBlur}
                          error={!!errors?.month && !!touched?.month}
                          helperText={errors.month && touched?.month ? errors?.month : ''}
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 8, md: 4 }}>
                    <Autocomplete
                      id="year"
                      name="year"
                      options={years}
                      getOptionLabel={(option) => option.toString()}
                      fullWidth
                      size="small"
                      value={years.find((option) => option === values?.year) || null}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(e, value) => {
                        setFieldValue('year', value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Year*"
                          onBlur={handleBlur}
                          error={!!errors?.year && !!touched?.year}
                          helperText={errors.year && touched?.year ? errors?.year : ''}
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 8, md: 4 }}>
                    <FastField
                      as={TextField}
                      name="magazineUrl"
                      id="magazineUrl"
                      // label="Magazine URL*"
                      label={values?.magazineAttachment ? 'Magazine URL' : 'Magazine URL*'}
                      disabled={!!values?.magazineAttachment}
                      fullWidth
                      size="small"
                      error={errors?.magazineUrl && touched?.magazineUrl}
                      // onChange={(e) => {
                      //   setFieldValue('magazineUrl', e.target.value);
                      //   if (e.target.value) {
                      //     setFieldValue('magazineAttachment', null);
                      //   }
                      // }}
                      onBlur={handleBlur}
                    />
                    {errors?.magazineUrl && touched?.magazineUrl && (
                      <FormHelperText error>{errors?.magazineUrl}</FormHelperText>
                    )}
                  </Grid2>

                  <Grid2 xs={12}>
                    <Typography align="center" sx={{ marginY: 1 }}>
                      OR
                    </Typography>
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 8, md: 4 }}>
                    {values?.magazineAttachment === null || values?.magazineAttachment === '' ? (
                      <Box>
                        <Button
                          name="magazineAttachment"
                          id="magazineAttachment"
                          variant="outlined"
                          fullWidth
                          startIcon={<CloudUploadIcon />}
                          // onClick={() => magazineFileRef.current.click()}
                          onClick={() => {
                            if (!values?.magazineUrl) {
                              magazineFileRef.current.click();
                              // setFieldTouched('magazineAttachment', true);
                            }
                          }}
                          disabled={!!values?.magazineUrl}
                          sx={{
                            color: '#181C32',
                            // borderColor: '#181C32',
                            borderColor:
                              errors?.magazineAttachment && touched?.magazineAttachment
                                ? 'error.main'
                                : '#181C32',
                            '&:hover': {
                              color: '#FFFFFF',
                              backgroundColor: '#181C32'
                            }
                          }}
                          onBlur={handleBlur}>
                          {/* Upload Magazine URL* */}
                          {values?.magazineUrl ? 'Upload Magazine' : 'Upload Magazine*'}
                          <VisuallyHiddenInput type="file" />
                        </Button>
                        <input
                          ref={magazineFileRef}
                          // accept=".doc,.docx,.pdf"
                          type="file"
                          name="magazineAttachment"
                          id="magazineAttachment"
                          style={{ display: 'none' }}
                          // onChange={(e) =>
                          //   setFieldValue('magazineAttachment', e.target.files[0])
                          // }
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file.size > 2097152) {
                              // 2 MB limit
                              setFieldError(
                                'magazineAttachment',
                                'File size must be less than 2 MB'
                              );
                              e.target.value = ''; // Reset the input
                            } else if (file) {
                              setFieldValue('magazineUrl', '');
                              setFieldValue('magazineAttachment', file);
                              showSnackBar('success', 'File Uploaded Successfully');
                            } else {
                              showSnackBar('error', 'Invalid File Format');
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {errors?.magazineAttachment && touched?.magazineAttachment && (
                          <FormHelperText error>{errors?.magazineAttachment}</FormHelperText>
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: 'fit-content'
                          }}>
                          Uploaded File:&nbsp;&nbsp;
                          {typeof values.magazineAttachment == 'object'
                            ? values.magazineAttachment.name
                            : rowData?.magazineAttachmentName}
                        </Typography>
                        <IconButton
                          // onClick={() => setFieldValue('magazineAttachment', null)}
                          onClick={() => {
                            setFieldValue('magazineAttachment', null);
                            // setFieldTouched('magazineAttachment', false);
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                    {/* {errors?.magazineAttachment && touched?.magazineAttachment && (
                    <FormHelperText error>{errors?.magazineAttachment}</FormHelperText>
                  )} */}
                  </Grid2>

                  <Grid2 xs="auto">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isActiveUrl"
                          id="isActiveUrl"
                          checked={values.isActiveUrl} // Dynamically read the value
                          onChange={(e) => {
                            setFieldValue('isActiveUrl', e.target.checked);
                          }}
                        />
                      }
                      label="Active"
                      labelPlacement="start"
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 8, md: 4 }}>
                    <FastField
                      as={TextField}
                      name="magazineDescription"
                      id="magazineDescription"
                      label="Magazine Description"
                      fullWidth
                      size="small"
                      error={errors?.magazineDescription && touched?.magazineDescription}
                    />
                    {errors?.magazineDescription && touched?.magazineDescription && (
                      <FormHelperText error>{errors?.magazineDescription}</FormHelperText>
                    )}
                  </Grid2>

                  <Grid2 size={{ xs: 12, sm: 8, md: 4 }}>
                    {values?.magazineFirstPageImage === null ? (
                      <Box>
                        <Button
                          name="magazineFirstPageImage"
                          id="magazineFirstPageImage"
                          variant="outlined"
                          fullWidth
                          startIcon={<CloudUploadIcon />}
                          // onClick={() => documentFileRef.current.click()}
                          onClick={() => {
                            documentFileRef.current.click();
                            // Mark the field as touched when clicking the upload button
                            // setFieldTouched('magazineFirstPageImage', true);
                          }}
                          sx={{
                            color: '#181C32',
                            // borderColor: '#181C32',
                            borderColor:
                              errors?.magazineFirstPageImage && touched?.magazineFirstPageImage
                                ? 'error.main'
                                : '#181C32',
                            '&:hover': {
                              color: '#FFFFFF',
                              backgroundColor: '#181C32'
                            }
                          }}
                          onBlur={handleBlur}>
                          Upload First Page Image*
                          <VisuallyHiddenInput type="file" />
                        </Button>
                        <input
                          ref={documentFileRef}
                          accept="image/jpeg, image/png"
                          // accept=".doc,.docx,.pdf"
                          type="file"
                          name="magazineFirstPageImage"
                          id="magazineFirstPageImage"
                          style={{ display: 'none' }}
                          // onChange={(e) => setFieldValue('magazineFirstPageImage', e.target.files[0])}
                          // onChange={(e) => {
                          //   setFieldValue('magazineFirstPageImage', e.target.files[0]);
                          //   setFieldTouched('magazineFirstPageImage', true);
                          // }}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            // console.log('file => ', file);
                            const validTypes = ['image/jpeg', 'image/png'];
                            const isFileValid = validTypes.includes(file.type);
                            if (file && isFileValid) {
                              setFieldValue('magazineFirstPageImage', file);
                              showSnackBar('success', 'Image Uploaded Successfully');
                              // setFieldTouched('magazineFirstPageImage', true);
                            } else if (file.size > 2097152) {
                              // 2 MB limit
                              setFieldError(
                                'magazineFirstPageImage',
                                'File size must be less than 2 MB'
                              );
                              e.target.value = ''; // Reset the input
                            } else {
                              setFieldValue('magazineFirstPageImage', null);
                              showSnackBar('error', 'Invalid File Format');
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {/* <FormHelperText
                        sx={{
                          color: '#d32f2f',
                          fontweight: 400
                        }}>
                        Supported formats: JPG, PNG
                      </FormHelperText> */}
                        {/* {errors?.magazineFirstPageImage && touched?.magazineFirstPageImage && (
                        <FormHelperText error>{errors?.magazineFirstPageImage}</FormHelperText>
                      )} */}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: 'fit-content'
                          }}>
                          Uploaded File:&nbsp;&nbsp;
                          {typeof values.magazineFirstPageImage == 'object'
                            ? values.magazineFirstPageImage.name
                            : rowData?.magazineFirstPageImageName}
                        </Typography>
                        <IconButton onClick={() => setFieldValue('magazineFirstPageImage', null)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                    {errors?.magazineFirstPageImage && touched?.magazineFirstPageImage && (
                      <FormHelperText error>{errors?.magazineFirstPageImage}</FormHelperText>
                    )}
                  </Grid2>
                </Grid2>

                <Divider sx={{ marginBlockStart: '1rem', marginBlockEnd: '1rem' }} />
                <Box>
                  <Button
                    variant="filled"
                    sx={{ mr: 2 }}
                    type="submit"
                    // onClick={() => handleSubmit(values)}
                    disabled={!dirty || !isValid}>
                    Save
                  </Button>
                  {/* {isSubmitting && <CircularProgress size={24} />} */}
                  <Button variant="outlined" onClick={closeModal}>
                    Cancel
                  </Button>
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddMagazine;
