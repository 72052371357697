import { createContext, useContext, useReducer } from 'react';

import newsReducer, { initialState } from '../../library/common/reducers/newsContextReducer';

const NewsContext = createContext();

export const useNewsContext = () => useContext(NewsContext);

const NewsProvider = ({ children }) => {
    const useNewsContextState = useReducer(newsReducer, initialState);

    return (
        <NewsContext.Provider value={[...useNewsContextState]}>
            {children}
        </NewsContext.Provider>
    );
};

export default NewsProvider;