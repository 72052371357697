import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getTokenFromStorage } from '../../utils/common';
const baseUrl = process.env.REACT_APP_API_URL;

const initialState = {
    loading: false,
    data: [],
    error: ''
};

export const fetchNewsDropDowns = createAsyncThunk('news/fetchDropDowns', async () => {
    const response = await axios.get(`${baseUrl}get-news-dropdowns`, {
        headers: {
            Authorization: `Bearer ${getTokenFromStorage()}`
        }
    });

    return response.data;
});

const newsSlice = createSlice({
    name: 'news',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewsDropDowns.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchNewsDropDowns.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
                state.error = '';
            })
            .addCase(fetchNewsDropDowns.rejected, (state, action) => {
                state.loading = false;
                state.data = [];
                state.error = action.error.message || 'Failed to fetch data';
            });
    }
});

export default newsSlice.reducer;