import React from 'react';
import {
  MaterialReactTable,
  MRT_ActionMenuItem,
  useMaterialReactTable
} from 'material-react-table';
import { Box, Button } from '@mui/material';

function Table({
  data,
  columns,
  isLoading,
  isError,
  rowSelection,
  setRowSelection,
  globalFilter,
  setGlobalFilter,
  sorting,
  setSorting,
  pagination,
  setPagination,
  rowCount,
  toolbarButtons,
  rowActionItems,
  state,
  children
}) {
  // Create the column order array with selection and action columns
  const fullColumnOrder = [
    'mrt-row-select', // Selection checkbox column
    ...columns.map((col) => col.accessorKey), // Your defined columns
    'mrt-row-actions' // Actions column
  ];

  // MaterialReactTable configuration
  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    enableStickyHeader: true,
    enableColumnActions: false,
    positionActionsColumn: 'last',
    enableRowSelection: true,
    enableGlobalFilter: true,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    getRowId: (row) => row.Id,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnOrder: fullColumnOrder
    },
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50,
        enableHiding: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableColumnOrdering: false,
        enableResizing: false,
        enableSorting: false
      },
      'mrt-row-actions': {
        enableHiding: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableColumnOrdering: false,
        enableResizing: false,
        enableSorting: false
      }
    },
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    enableRowActions: true,
    enableSortingRemoval: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data'
      }
      : undefined,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: () => ({
        maxHeight: 'calc(100vh - 300px)'
      })
    },
    muiTableHeadCellProps: {
      sx: () => ({
        backgroundColor: '#f3f6f9',
        fontWeight: '600',
        fontSize: '1rem',
        borderBottomWidth: '1px',
        paddingTop: '0.7rem',
        paddingBottom: '0.7rem',
        textWrap: 'nowrap'
      })
    },
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        {toolbarButtons &&
          toolbarButtons.length > 0 &&
          toolbarButtons.map((button, index) => (
            <Button
              key={index}
              variant={button.variant}
              sx={button.sx}
              color={button.color}
              onClick={button.onClick}
              startIcon={button.startIcon}>
              {button.label}
            </Button>
          ))}
        {children}
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu, row, table }) =>
      rowActionItems &&
      rowActionItems.length > 0 &&
      rowActionItems.map((item, index) => (
        <MRT_ActionMenuItem
          key={index}
          icon={item.icon}
          label={item.label}
          onClick={() => {
            closeMenu();
            item.onClick(row.original, row.index);
          }}
          table={table}
        />
      )),
    rowCount,
    ...state,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      sorting,
      rowSelection,
      columnOrder: fullColumnOrder
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
}

export default Table;
