import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { postRequest } from '../../../../helpers/httpHelper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material';
import { orange } from '@mui/material/colors';

export function AddRoleAccess() {
  const [roleMap, setRoleMap] = useState([]);

  // console.log("roleMap => ", roleMap);

  const { roleID } = useParams();

  const fetchRoles = () => {
    postRequest('fetch-role', { role_ID: roleID }, function (response) {
      if (!response?.data?.error && response?.status === 200) {
        setRoleMap(response?.data?.data?.roleAccess);
      }
    });
  };

  const updateRoleAccess = (updatedRoleAccess) => {
    const payload = {
      role_ID: roleID,
      roleAccess: updatedRoleAccess
    };

    postRequest('update-role-access', payload, function (response) {
      if (!response?.data?.error && response?.status === 200) {
        setRoleMap(updatedRoleAccess);
      }
    });
  };

  const handleRadioChange = (index, value) => {
    const updatedRoleAccess = [...roleMap];
    updatedRoleAccess[index] = {
      ...updatedRoleAccess[index],
      none: value === 'None' ? 1 : 0,
      view: value === 'View' ? 1 : 0,
      add: value === 'Add' ? 1 : 0,
      delete: value === 'Delete' ? 1 : 0
    };
    updateRoleAccess(updatedRoleAccess);
  };

  useEffect(() => {
    fetchRoles();
  }, [roleID]);

  return (
    <TableContainer component={Paper}>
      <Typography
        variant="h6"
        sx={{ fontWeight: 'bold', marginBottom: 2, marginTop: '10px', marginLeft: '10px' }}>
        Role Mapping
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>No.</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Functions</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Entitlements</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (roleMap && roleMap?.length > 0) &&
            roleMap?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.functionalityName}</TableCell>
                <TableCell>
                  <FormControl>
                    <RadioGroup
                      row
                      name={`row-radio-buttons-group-${index}`}
                      value={
                        item?.none
                          ? 'None'
                          : item?.view
                            ? 'View'
                            : item?.add
                              ? 'Add'
                              : item?.delete
                                ? 'Delete'
                                : 'None'
                      }
                      onChange={(e) => handleRadioChange(index, e.target.value)}
                    >
                      {['None', 'View', 'Add', 'Delete'].map((label) => (
                        <FormControlLabel
                          key={label}
                          value={label}
                          control={
                            <Radio
                              sx={{
                                color: orange[800],
                                '&.Mui-checked': { color: orange[600] }
                              }}
                            />
                          }
                          label={label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AddRoleAccess;
