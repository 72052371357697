import * as actionTypes from '../constants/StoreConstants';

export const initialState = {
  companyDetails: {},
  notesDetails: {}
};

const companyReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_COMPANYDETAILS:
      return { ...state, companyDetails: { ...state.companyDetails, ...payload } };
    case actionTypes.SET_NOTESDETAILS:
      // return { ...state, notesDetails: { ...state.notesDetails, ...payload } };
      return { ...state, notesDetails: Object.keys(payload).length === 0 ? {} : { ...state.notesDetails, ...payload } };
    default:
      return state;
  }
};

export default companyReducer;
