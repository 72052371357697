import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from '../../library/common/reducers/AuthReducer';
import companyReducer from './companySlice';
import projectReducer from './projectSlice';
import tendersReducer from './tenderSlice';
import orderReducer from './orderSlice'
import newsReducer from './newsSlice';
import leadReducer from './leadSlice'
import updateSubMenuCountReducer from '../../library/common/reducers/updateSubMenuCountReducer';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    company: companyReducer,
    tender: tendersReducer,
    project: projectReducer,
    order: orderReducer,
    news: newsReducer,
    update: updateSubMenuCountReducer,
    lead: leadReducer
  }
});

export default store;
