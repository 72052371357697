import React, { lazy } from 'react';
import {
  SubNavMenuCompany,
  SubNavMenuProjects,
  // SubNavMenuContracts,
  SubNavMenuTenders,
  SubNavMenuUser,
  SubNavMenuProjectConfig,
  SubNavMenuCompanyConfig,
  SubNavMenuOrder,
  SubNavMenuNews,
  SubNavMenuMagazine,
  SubNavMenuSalesCRM
} from './subNav';
import { Outlet } from 'react-router-dom';
const Home = lazy(() => import('../../screens/Main/Home/Home'));

const ArrayForNav = [
  'project',
  'tender',
  'contract',
  'company',
  'order',
  'news',
  'magazine',
  'manage-user',
  'project-configuration',
  'company-configuration',
  'sales-crm'
];

const getSubNavMenu = (path) => {
  switch (path) {
    case 'project':
      return SubNavMenuProjects;
    case 'tender':
      return SubNavMenuTenders;
    // case 'contract':
    //   return SubNavMenuContracts;
    case 'company':
      return SubNavMenuCompany;
    case 'order':
      return SubNavMenuOrder;
    case 'news':
      return SubNavMenuNews;
    case 'magazine':
      return SubNavMenuMagazine;
    case 'manage-user':
      return SubNavMenuUser;
    case 'project-configuration':
      return SubNavMenuProjectConfig;
    case 'company-configuration':
      return SubNavMenuCompanyConfig;
    case 'sales-crm':
      return SubNavMenuSalesCRM;
    default:
      return [];
  }
};

const superAdminNav = ArrayForNav?.map((path) => {
  return {
    path: path,
    element: <Outlet />,
    children: getSubNavMenu(path)
  };
});

export default superAdminNav;
