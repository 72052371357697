import * as actionTypes from '../constants/StoreConstants';

export const initialState = {
    news: {},
    newsMultiple: {},
    newsContent: {}
};

const newsReducer = (state, { type, payload }) => {
    switch (type) {
        case actionTypes?.SET_NEWS:
            return { ...state, news: { ...state.news, ...payload } };
        case actionTypes?.SET_NEWSMULTIPLE:
            return { ...state, newsMultiple: { ...state.newsMultiple, ...payload } };
        case actionTypes?.SET_NEWSCONTENT:
            // return { ...state, newsContent: { ...state.newsContent, ...payload } };
            return { ...state, newsContent: Object.keys(payload).length === 0 ? {} : { ...state.newsContent, ...payload } };
        default:
            return state;
    }
};

export default newsReducer;