import * as actionTypes from '../constants/StoreConstants';

export const initialState = {
    leadInfo: {},
    leadInvoice: {}
};

const leadReducer = (state, { type, payload }) => {
    switch (type) {
        case actionTypes?.SET_LEADINFO:
            return { ...state, leadInfo: { ...state.leadInfo, ...payload } };
        case actionTypes?.SET_LEADINVOICE:
            return { ...state, leadInvoice: { ...state.leadInvoice, ...payload } };
        default:
            return state;
    }
};

export default leadReducer;
